import React from "react";
import "./PortfolioCardList.scss";
import bridge from "../../assets/sites/bridgepizza.jpg";
import bop from "../../assets/sites/bop.jpg";
import brainflix from "../../assets/sites/brainflix.jpg";
import dpf from "../../assets/sites/dpf.jpg";
import grindstone from "../../assets/sites/grindstone.jpg";
import lago from "../../assets/sites/lago.jpg";
import myarchive from "../../assets/sites/myarchive.jpg";
import redrock from "../../assets/sites/redrock-pizza.jpg";
import shamrock from "../../assets/sites/shamrock.jpg";
import travelsite from "../../assets/sites/travelsite.jpg";
import bandsite from "../../assets/sites/bandsite.jpg";
import scafit from "../../assets/sites/scafit.jpg";
import instock from "../../assets/sites/instock.jpg";
import coffeeshop from "../../assets/sites/coffeeshop.jpg";
import shopify from "../../assets/sites/shopify.jpg";
import PortfolioCard from "../PortfolioCard/PortfolioCard";
import scafitVideo from "../../assets/videos/scafit.mp4";
import redline from "../../assets/sites/redline.jpg";
import mohrs from "../../assets/sites/mohrs.jpg";
import { v4 as uuidv4 } from "uuid";

const PortfolioCardList = () => {
  const body = () => {
    return (
      <section className="portfolio-card-list">
        {portfolioCards &&
          portfolioCards.map((card, index) => {
            if (card.name === "spacer") {
              return (
                <div
                  key={uuidv4()}
                  className="portfolio-card-list__spacer"
                ></div>
              );
            }
            return <PortfolioCard key={uuidv4()} card={card} />;
          })}
      </section>
    );
  };

  const portfolioCards = [
    {
      name: "Redline Equipment - Jul 2022",
      img: redline,
      link: "",
      git: [],
      tech: "React, FireBase, FireStore, FireStorage, ",
      description:
        "A simple SPA landing page web app for a local heavy equipment rental agency. Includes a custom CMS for owner to manage site.",
    },
    {
      name: "spacer",
    },
    {
      name: "Mohrs Landscaping - Jun 2022",
      img: mohrs,
      link: "https://landscaping.mohrsgroup.com",
      git: [],
      tech: "Codeigniter, MySQL, Bootstrap",
      description:
        "Created a multisite CMS for a local construction company using Codeigniter, Bootstrap and MySQL to allow for multi subdomain support of various branches of the company.",
    },
    {
      name: "spacer",
    },
    {
      name: "SCAFit - Mar 2022",
      img: scafit,
      link: "https://scafit.ca",
      git: [
        {
          link: "https://github.com/nicholas-hucal/scaf-it",
          text: "Front End",
        },
        {
          link: "https://github.com/nicholas-hucal/scaf-it-server",
          text: "Server",
        },
      ],
      tech: "React, Express, Oauth, Axios, Knex, Passport, MySql",
      description:
        "A custom React and Express project for efficient react component development. Takes simple user input via a GUI to create components ready to be deployed in a react app. Capstone project for my diploma program @ BrainStation",
      video: scafitVideo,
    },
    {
      name: "Bridge Pizza - Jan 2021",
      img: bridge,
      link: "https://bridgepizza.ca",
      git: [],
      tech: "Statamic, Bootstrap, Firebase, JQuery",
      description:
        "Designed and developed a custom online ordering system using Statamic and Firebase, along with front end website. Includes live tracking of orders for both client and kitchen.",
    },
    {
      name: "Brainflix - Mar 2022",
      img: brainflix,
      link: "https://brainflix-fc82f.web.app",
      git: [
        {
          link: "https://github.com/nicholas-hucal/nicholas-hucal-brainflix",
          text: "Front End",
        },
        {
          link: "https://github.com/nicholas-hucal/nicholas-hucal-brainflix-api",
          text: "Server",
        },
      ],
      tech: "React, Express, Node, Heroku, Axios",
      description:
        "A React and Express based project built as a youtube clone during training @ BrainStation. Includes features such as form validation, commenting, file upload and dynamic routing.",
    },
    {
      name: "Bandsite - Feb 2022",
      img: bandsite,
      link: "https://bandsite-nhucal.web.app",
      git: [
        {
          link: "https://github.com/nicholas-hucal/nicholas-hucal-bandsite",
          text: "Front End",
        },
      ],
      tech: "React, Heroku, Axios, Figma, HTML5, CSS3",
      description:
        "A React and Express based project built during training @ BrainStation. This project was a lot of fun, and has opened a few doors for future projects.",
    },
    {
      name: "spacer",
    },
    {
      name: "Shopify Partner Program - Feb 2022",
      img: shopify,
      link: "",
      git: [],
      tech: "React, Jira, Figma, HTML5, CSS3",
      description:
        "A product of a collaborative effort during a hackathon as a deliverable requested by Shopify. Working with a data scientist, 2 developers and 2 designers we provided this solution to Shopify in a 24 hour period.",
    },
    {
      name: "spacer",
    },
    {
      name: "Instock - Feb 2022",
      img: instock,
      link: "https://instock-nhucal.web.app/",
      git: [
        {
          link: "https://github.com/nicholas-hucal/instock-belle",
          text: "Front End",
        },
        {
          link: "https://github.com/nicholas-hucal/instock-api-belle",
          text: "Server",
        },
      ],
      tech: "React, Express, Node, Axios, Heroku, Jira, Figma, HTML5, CSS3",
      description:
        "A collaborative effort with 4 programmers during my training @ BrainStation, provides a CMS for a warehouse. Using the power of React and Express we were able to create this project in just 5 days.",
    },
    {
      name: "spacer",
    },
    {
      name: "TravelSite - Jan 2022",
      img: travelsite,
      link: "https://travelsite-nhucal.web.app",
      git: [
        {
          link: "https://github.com/nicholas-hucal/nicholas-hucal-travelsite",
          text: "Source",
        },
      ],
      tech: "HTML5, CSS3, Heroku",
      description:
        "A website built using modern HTML and CSS during my training @ BrainStation.",
    },
    {
      name: "Coffee Shop - Jan 2022",
      img: coffeeshop,
      link: "https://coffee-shop-23224.web.app",
      git: [
        {
          link: "https://github.com/nicholas-hucal/coffee-shop",
          text: "Source",
        },
      ],
      tech: "HTML5, CSS3, Heroku",
      description:
        "A website built using modern HTML and CSS during my training @ BrainStation",
    },
    {
      name: "myArchive - Oct 2021",
      img: myarchive,
      link: "https://myarchive.ca",
      git: [],
      tech: "Codeigniter, Bootstrap, PHP, MySql, JS, Jquery, DataTables",
      description:
        "A custom PHP and JS CMS built on CodeIgniter for my family to maintain family recipes and photos, very feature rich.",
    },
    {
      name: "Shamrock Curling - Mar 2020",
      img: shamrock,
      link: "https://shamrockcurling.ca",
      git: [],
      tech: "Statamic, Bootstrap, Jquery",
      description:
        "A ground up solution for Shamrock Curling providing a customizable website with Statamic as a frame. ",
    },
    {
      name: "spacer",
    },
    {
      name: "DPF Solutions - Dec 2019",
      img: dpf,
      link: "https://dpfsolutions.ca",
      git: [],
      tech: "Codeigniter, Bootstrap, PHP, MySql",
      description:
        "Designed and developed a website for a local heavy equipment manufacturing company including all artwork. Based on the powerful codeigniter, I delivered a front and back end for the client.",
    },
    {
      name: "spacer",
    },
    {
      name: "Grindstone - Sep 2019",
      img: grindstone,
      link: "",
      git: [],
      tech: "Codeigniter, Bootstrap, PHP, MySql",
      description:
        "A custom PHP based CRM, CMS and powerful paperwork/estimation software solution built for a local construction company. Modular and Scalable this solution has been working flawlessly for 3 years.",
    },
    {
      name: "Red Rock Pizza -  Jul 2019",
      img: redrock,
      link: "https://redrockpizza.ca",
      git: [],
      tech: "Codeigniter, Bootstrap, PHP, MySql",
      description:
        "Simple informational website built using codeigniter providing a front and back end for the client. All design and development was provided by me.",
    },

    {
      name: "Lago Pizza -  Jun 2018",
      img: lago,
      link: "http://lagopizza.ca",
      git: [],
      tech: "Codeigniter, Bootstrap, PHP, MySql",
      description:
        "Simple informational website built using codeigniter providing a front and back end for the client",
    },
    {
      name: "spacer",
    },
    {
      name: "spacer",
    },
    {
      name: "Breath of Prayer -  Jan 2018",
      img: bop,
      link: "https://breathofprayer.ca",
      git: [],
      tech: "Statamic, Bootstrap, Jquery, Firebase, Swift",
      description:
        "Designed and developed a website and app using Statamic, PHP, Firebase and Swift. App available in Apple App Store.",
    },
  ];

  return body();
};

export default PortfolioCardList;
