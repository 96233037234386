import React from 'react';
import './Nav.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Nav = () => {
  return (
    <nav className='nav'>
        <AnchorLink className='nav__brand' href='#home'>NH</AnchorLink>
        <ul className='nav__list'>
            <li className='nav__list-item'>
                <AnchorLink className='nav__link' href='#portfolio'>portfolio</AnchorLink>
            </li>
            <li className='nav__list-item'>
                <AnchorLink className='nav__link' href='#skills'>skills</AnchorLink>
            </li>
            <li className='nav__list-item'>
                <AnchorLink className='nav__link' href='#contact'>contact</AnchorLink>
            </li>
        </ul>
    </nav>
  )
}

export default Nav