import React from 'react';
import './DiagonalBorder.scss';

const DiagonalBorder = ({direction, invert, children, scheme}) => {
  return (
    <section className={`diagonal-border ${invert && `diagonal-border--inverted`}`}>
      <div className={`diagonal-border__angle ${direction === 'right' ? 'diagonal-border__angle--top-right' : 'diagonal-border__angle--top'}  ${scheme && `diagonal-border__angle--dark`}`}></div>
      {children}
      <div className={`diagonal-border__angle ${direction === 'right' ? 'diagonal-border__angle--bottom-right' : 'diagonal-border__angle--bottom'}`}></div>
    </section>
  )
}

export default DiagonalBorder