import React, { useState } from "react";
import Modal from "../Modal/Modal";
import "./PortfolioCard.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../data/firebase-config";

const PortfolioCard = ({ card }) => {
  const [modal, setModal] = useState(false);
  const { name, img, link, description, video, tech, git } = card;
  const modalToggle = () => {
    !modal && logEvent(analytics, `${name}_opened`);
    setModal((prev) => !prev);
  };

  return (
    <>
      <div className="portfolio-card" onClick={modalToggle}>
        <AnimationOnScroll
          animateIn="animate__bounceInUp"
          duration="0.5"
          animateOnce="true"
        >
          <img className="portfolio-card__image" src={img} alt={name} />
        </AnimationOnScroll>
      </div>
      {modal && (
        <Modal
          name={name}
          link={link}
          description={description}
          img={img}
          tech={tech}
          git={git}
          demo={video}
          modalToggle={modalToggle}
        />
      )}
    </>
  );
};

export default PortfolioCard;
