import React from "react";
import { DiGithubBadge } from "react-icons/di";
import { BsMailbox } from "react-icons/bs";
import { AiOutlineLinkedin } from "react-icons/ai";
import { AnimationOnScroll } from "react-animation-on-scroll";
import DiagonalBorder from "./components/DiagonalBorder/DiagonalBorder";
import PortfolioCardList from "./components/PortfolioCardList/PortfolioCardList";
import TechStackList from "./components/TechStackList/TechStackList";
import Divider from "./components/Divider/Divider";
import Highlighted from "./components/Highlighted/Highlighted";
import Nav from "./components/Nav/Nav";
import { logEvent } from "firebase/analytics";
import { analytics } from "./data/firebase-config";
import "animate.css/animate.min.css";
import "./App.scss";

function App() {
  logEvent(analytics, "homepage_visited");

  return (
    <main>
      <Nav />
      <div className="app">
        <div className="app__header" id="home">
          <h1 className="app__heading">Nicholas Hucal</h1>
          <p className="app__subheading">full stack web developer</p>
        </div>
        <DiagonalBorder direction="left" invert={true} scheme="dark">
          <div className="app__section">
            <AnimationOnScroll
              animateIn="animate__slideInLeft"
              animateOnce="true"
              duration="0.7"
            >
              <p className="app__lead">
                Having spent over <Highlighted text="10 years" /> as a project
                manager and estimator in the construction field, I felt it was
                time for a <Highlighted text="pivot" /> to a more rewarding,{" "}
                <Highlighted text="challenging" /> and flexible career that
                allows for a better work/life balance with my passion as a{" "}
                <Highlighted text="self sufficient homesteader" />.
              </p>
            </AnimationOnScroll>
          </div>
        </DiagonalBorder>
        <section className="app__container--dark" id="portfolio">
          <div className="app__section">
            <p className="app__display-heading">latest &amp; </p>
            <p className="app__display-subheading">greatest</p>
            <PortfolioCardList />
          </div>
        </section>
        <DiagonalBorder direction="right" invert={true}>
          <div className="app__section">
            <AnimationOnScroll
              animateIn="animate__slideInLeft"
              animateOnce="true"
              duration="0.7"
            >
              <p className="app__lead">
                Leveraging skills learned throughout my career such as;{" "}
                <Highlighted text="problem solving" />, business communication,
                customer service and <Highlighted text="teamwork" />, I have
                applied myself to become a full stack developer through
                attending a <Highlighted text="diploma program" /> at
                BrainStation and continual <Highlighted text="self learning" />.
              </p>
            </AnimationOnScroll>
          </div>
        </DiagonalBorder>
        <section className="app__container--dark" id="skills">
          <div className="app__section">
            <p className="app__display-heading">skills &amp;</p>
            <p className="app__display-subheading">experience</p>
            <div className="app__skills-experience">
              <TechStackList />
              <div className="app__experience">
                <dl className="app__exp-list">
                  <dt className="app__exp-list-item">
                    Jul 2022 - Present <Divider /> IRCC Gov't of Canada{" "}
                    <Divider /> Full Stack Developer
                  </dt>
                  <dd className="app__exp-list-details">
                    <ul>
                      <li className="app__bullets">
                        Participated in daily agile ceremonies that led to a
                        productive team development environment.
                      </li>
                      <li className="app__bullets">
                        Created solutions for program requirements using a full
                        stack approach utilizing cloud, server and front end
                        languages.
                      </li>
                      <li className="app__bullets">
                        Worked independently on tickets that led to efficient
                        and readable code, which was implemented into the live
                        codebase.
                      </li>
                      <li className="app__bullets">
                        Continually upgraded skills to learn new programming
                        techniques wherever possible.
                      </li>
                    </ul>
                  </dd>

                  <dt className="app__exp-list-item">
                    Jan 2022 - Apr 2022 <Divider /> BrainStation <Divider /> Web
                    Development Diploma
                  </dt>
                  <dd className="app__exp-list-details">
                    <ul>
                      <li className="app__bullets">
                        Involved in many solo and group programming projects
                        that led to becoming a full stack developer
                      </li>
                      <li className="app__bullets">
                        Learned all modern JS, HTML and CSS skills to allow for
                        proper coding moving forward
                      </li>
                      <li className="app__bullets">
                        Earned a diploma as a developer for web based
                        technologies such as React, Node and Express
                      </li>
                      <li className="app__bullets">
                        Educated on how to continue the education process by
                        self learning new skills as needed{" "}
                      </li>
                    </ul>
                  </dd>

                  <dt className="app__exp-list-item">
                    Sept 2019 - present <Divider /> Shamrock Curling <Divider />{" "}
                    Board of Directors
                  </dt>
                  <dd className="app__exp-list-details">
                    <ul>
                      <li className="app__bullets">
                        Participated in monthly meetings to provide the smooth
                        operation of a local curling club, learning many
                        communication skills
                      </li>
                      <li className="app__bullets">
                        Involved on many subcomittees, I was tasked to handle
                        various club business to provide multiple solutions.
                      </li>
                      <li className="app__bullets">
                        Trusted with position on the executive as the treasurer,
                        I was tasked to provide oversight to club spending.
                      </li>
                    </ul>
                  </dd>

                  <dt className="app__exp-list-item">
                    Sept 2017 - present <Divider /> Pinecone Workshop{" "}
                    <Divider /> Web Developer
                  </dt>
                  <dd className="app__exp-list-details">
                    <ul>
                      <li className="app__bullets">
                        Provided quality promotional, digital and craft items to
                        a variety of clients throughout Alberta, which allowed
                        for constant revenue growth, and for my ability to be
                        flexible on client requirements.
                      </li>
                      <li className="app__bullets">
                        Constantly adapted our product lineup which kept our
                        clients loyal while allowing me to gain insight into
                        market demands.
                      </li>
                      <li className="app__bullets">
                        Learned all required skills to run a private business
                        that allowed for a smooth operating environment and
                        continued success. Through this I have learned to be a
                        proficient self starter and learner.
                      </li>
                    </ul>
                  </dd>

                  <dt className="app__exp-list-item">
                    May 2009 - June 2022 <Divider /> Mohr's Landscaping{" "}
                    <Divider /> Project Estimator
                  </dt>
                  <dd className="app__exp-list-details">
                    <ul>
                      <li className="app__bullets">
                        Accountable for daily activities of a construction
                        company to provide for the delivery of a safe work
                        environment and quality end product.
                      </li>
                      <li className="app__bullets">
                        Developed and implemented a digital safety program which
                        allowed for the safe daily operation of the company. I
                        was able to gain a wealth of knowledge of efficient
                        business communication techniques and tools through this
                        project.
                      </li>
                      <li className="app__bullets">
                        Responsible for the bidding of projects, large and small
                        scale, which resulted in the acquisition of over
                        $3million in contracts. I gained the ability to
                        interface with clients and other contractors to complete
                        the arduous task of compiling and winning these
                        contracts.
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </section>
        <DiagonalBorder direction="left" invert={true}>
          <div className="app__section">
            <AnimationOnScroll
              animateIn="animate__slideInLeft"
              animateOnce="true"
              duration="0.7"
            >
              <p className="app__lead">
                Having <Highlighted text="experienced" /> coding as a{" "}
                <Highlighted text="freelancer" /> for the past 10 years, coupled
                with my skills that are highly{" "}
                <Highlighted text="transferable" /> from past and present
                positions I have attained the ability to provide quality code
                that is <Highlighted text="readable and reusable" /> by other
                developers, while operating in a professional environment.
              </p>
            </AnimationOnScroll>
          </div>
        </DiagonalBorder>
        <section className="app__container--dark" id="contact">
          <div className="app__section">
            <p className="app__display-heading">contact</p>
            <p className="app__contact">
              <a className="app__contact-link" href="mailto:nhucal@mac.com">
                <span className="app__contact-icon">
                  <BsMailbox />
                </span>{" "}
                &nbsp;nhucal@mac.com
              </a>
              <a
                className="app__contact-link"
                href="https://www.linkedin.com/in/nicholas-hucal/"
              >
                <span className="app__contact-icon">
                  <AiOutlineLinkedin />
                </span>{" "}
                &nbsp;LinkedIn
              </a>
              <a
                className="app__contact-link"
                href="https://github.com/nicholas-hucal"
              >
                <span className="app__contact-icon">
                  <DiGithubBadge />
                </span>{" "}
                GitHub
              </a>
            </p>
          </div>
        </section>
      </div>
    </main>
  );
}

export default App;
