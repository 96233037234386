import React from 'react';
import './TechStackList.scss';
import TechStackIcon from '../TechStackIcon/TechStackIcon';
import { DiBootstrap, DiAngularSimple, DiCodeigniter, DiCss3, DiJoomla, DiGithubBadge, DiHeroku, DiHtml5, DiJqueryLogo, DiJsBadge, DiMongodb, DiMysql, DiNodejsSmall, DiNpm, DiPhp, DiReact, DiSass } from 'react-icons/di';
import { SiStatamic, SiAdobe, SiAmazonaws, SiExpress, SiFirebase, SiGooglemaps, SiJira, SiPassport, SiSketchup, SiSlack, SiSwift, SiFigma, SiAzuredevops, SiMicrosoftteams } from 'react-icons/si';

const TechStackList = () => {

    const techs = [<DiReact />, <DiAngularSimple />, <SiAmazonaws />, <DiJsBadge />, <DiPhp />, <DiNodejsSmall />, <DiMysql />, <SiAzuredevops/>, <SiExpress />, <DiJqueryLogo />, <DiHtml5 />, <DiCss3 />, <DiSass />, <DiNpm />, <DiCodeigniter />, <DiGithubBadge />, <SiFirebase />, <DiBootstrap />, <DiMongodb />, <DiHeroku />, <DiJoomla />, <SiStatamic />, <SiAdobe />, <SiGooglemaps />, <SiJira />, <SiPassport />, <SiSketchup />, <SiSlack />, <SiSwift />, <SiFigma />, <SiMicrosoftteams/>]

    return (
        <div className='tech-stack-list'>
            {techs.map((tech, i) => <TechStackIcon key={i}>{tech}</TechStackIcon>)}
        </div>
    )
}

export default TechStackList