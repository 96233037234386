import './Modal.scss';
import { useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { RiComputerLine } from 'react-icons/ri';
import { DiGithubFull, DiCodeBadge } from 'react-icons/di';
import { v4 as uuidv4 } from 'uuid';

const Modal = ({ name, link, description, img, demo, git, tech, modalToggle }) => {

    const [animation, setAnimation] = useState(false);

    const close = () => {
        setAnimation(prev => !prev);
        setTimeout(() => {
            modalToggle();
        }, 350)
    }

    return (
        <div className={`modal ${animation && 'modal--out'}`} onClick={close} >
            <div className='modal__content' onClick={e => e.stopPropagation()}>
                <div className='modal__close'>
                    <AiOutlineCloseCircle onClick={close} />
                </div>
                <div className='modal__body'>
                    <h2 className='modal__name'>{name}</h2>
                    <p className='modal__desc'>{description}</p>
                    {tech &&
                        <div className='modal__tech'>
                            <DiCodeBadge className='modal__icon' /> 
                            {tech}
                        </div>
                    }
                    {git.length > 0 &&
                        <div className='modal__tech'>
                            <DiGithubFull className='modal__icon' />
                            {git.map(g => <a className='modal__link' key={uuidv4()} href={g.link}>{g.text} Code</a>)}
                        </div>
                    }
                    {demo ?
                        <video className='modal__video' src={demo} height="auto" width="100%" controls="controls"/>
                        :
                        <>
                            {link !== '' && 
                                <div className="modal__tech">
                                    <RiComputerLine className='modal__icon' />
                                    <a href={link} className='modal__link'>View Live Here</a>
                                </div>
                            }
                            <img className='modal__image' src={img} alt={name} />
                        </>
                    }
                </div>
            </div>
        </div>
    )

}

export default Modal